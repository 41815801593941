import React from "react"
import { Spinner } from "reactstrap"
import "./spinnerOverlay.scss"

const SpinnerOverlay = ({ show }) => {
  return show ? (
    <Spinner
      data-testid="spinner-overlay"
      style={{ height: "3rem", width: "3rem" }}
      className="position-absolute spinner-overlay"
      color="primary"
    />
  ) : null
}

export default SpinnerOverlay
