import React, { useEffect, useReducer } from "react"
import { isAuthenticatedAsync } from "../utils/auth.utils"
import Users from "../utils/apis/users"

const initialState = {
  data: {},
  status: "idle",
  error: "",
}

const UserContext = React.createContext(initialState)

const reducer = (state, { type, payload }) => {
  // eslint-disable-next-line
  switch (type) {
    case "pending":
      return { ...state, status: payload.status }
    case "resolved":
      return { ...state, status: payload.status, data: payload.data }
    case "rejected":
      return { ...state, status: payload.status, error: payload.error }
    case "updateUserData":
      return { ...state, data: payload.data }
  }
}

const UserContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState),
    { data, status, error } = state

  useEffect(() => {
    ;(async () => {
      try {
        dispatch({ type: "pending", payload: { status: "pending" } })
        const userObj = await isAuthenticatedAsync()
        const emailStatus = userObj.attributes.email_verified
        const userId = userObj.attributes.sub
        const user = await Users.get(userId)
        user.email_verified = emailStatus ? true : false
        user.isOwner = user?.stripe_customer_id ? true : false
        user.accessToken = userObj?.signInUserSession?.idToken?.jwtToken
        dispatch({ type: "resolved", payload: { status: "resolved", data: user } })
      } catch (error) {
        dispatch({ type: "rejected", payload: { status: "rejected", error: error.message } })
      }
    })()
  }, [])

  const updateUserData = async () => {
    const userObj = await isAuthenticatedAsync()
    const userId = userObj.attributes.sub
    const user = await Users.get(userId)
    user.email_verified = userObj.attributes.email_verified ? true : false
    user.isOwner = user?.stripe_customer_id ? true : false
    dispatch({ type: "updateUserData", payload: { data: user } })
  }

  return (
    <UserContext.Provider
      value={{
        data,
        status,
        error,
        updateUserData,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserContextProvider }
