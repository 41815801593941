import client from "../api-client"

export default class IntegrationHelpMessages {
  static create = (body) => {
    return client("messages", { method: "POST", body })
  }

  static query = (user_id) => {
    return client(`messages?user_id=${user_id}`)
  }

  static update = (body) => {
    return client("messages", {
      method: "PATCH",
      body,
    })
  }

  static socketURL = () => {
    return process.env.REACT_APP_WEBSOCKET_URL
  }

  static getChatToken = () => {
    return client("chat-token")
  }
}
