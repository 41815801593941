import React from "react"
import Header from "../Header"
import Footer from "../Footer"

const Layout = ({ children, authenticated = false, privacy }) => {
  return (
    <div className="d-flex flex-column full-height">
      <Header authenticated={authenticated} privacy={privacy} />
      <div className="pb-4">{children}</div>
      <Footer />
    </div>
  )
}

export default Layout
