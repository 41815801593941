import { useEffect, useState } from "react"
import ReactGA from "react-ga4"
import { useLocation } from "react-router-dom"

const RouterTracker = () => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const isGAEnabled = process.env.REACT_APP_STAGE === "prod"

    if (isGAEnabled) {
      ReactGA.initialize("G-7NRJ6NXY8Q")
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
      })
    }
  }, [initialized, location])
}

export default RouterTracker
