import React from "react"
import { createRoot } from "react-dom/client"
// import * as Sentry from "@sentry/react"
// import { BrowserTracing } from "@sentry/tracing"
import App from "./App"
import { Amplify } from "aws-amplify"
import { QueryClientProvider } from "@tanstack/react-query"
import awsconfig from "./awsconfig"
import "./index.scss"
import { queryClient } from "./utils/functions.utils"
import { BrowserRouter } from "react-router-dom"

Amplify.configure(awsconfig)

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </QueryClientProvider>
)
