import client from "../api-client"

export default class PMSchedule {
  static get = () => {
    return client("pm-schedule")
  }

  static update = (body) => {
    return client("pm-schedule", {
      method: "PATCH",
      body,
    })
  }
}
