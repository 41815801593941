import { Helmet } from "react-helmet"
import React from "react"
const CrazyEggTracker = () => {
  const isProd = process.env.REACT_APP_STAGE === "prod"

  return isProd ? (
    <Helmet>
      <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0102/6686.js" async="async" />
    </Helmet>
  ) : null
}

export default CrazyEggTracker
