import client from "../api-client"

export default class Users {
  static create = (body) => {
    return client("users", { method: "POST", body })
  }

  static get = (user_id) => {
    return client(`users?user_id=${user_id}`)
  }

  static update = (body) => {
    return client("users", {
      method: "PATCH",
      body,
    })
  }

  static delete = async (item_id) => {
    return client(`users?user_id=${item_id}`, {
      method: "DELETE",
    })
  }

  // Only remove user from a child team. No deletion
  static remove_user = async ({ user_id, team_id }) => {
    return client(`remove-user?user_id=${user_id}&team_id=${team_id}`, {
      method: "DELETE",
    })
  }

  static batch_create = (body) => {
    return client("organization-users", { method: "POST", body })
  }

  static list = (team_id) => {
    return client(`list_users?team_id=${team_id}`)
  }

  static list_members = (slug) => {
    return client(`list_users?slug=${slug}`)
  }
}
